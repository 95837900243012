import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import "./index.scss"
import dbServices from "../../../services/firebase/db"

import { kebabCase } from "lodash"

const More = () => {
  const [mail, setMail] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)
  const [requiredd, setRequiredd] = useState(false)
  let tagslist = []
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(limit: 2000) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
      }
    `)
    tagslist = result.allMdx.group
  } catch (error) {
    console.log(error)
  }
  const sendUserMail = async () => {
    setRequiredd(false)

    await dbServices.addUserMail({
      id: "listMails",
      mails: [
        {
          mail,
        },
      ],
    })
    setMail("")
    setShowSuccess(true)
    setTimeout(() => {
      setShowSuccess(false)
    }, 6000)
    return
  }
  return (
    <div className="moreblogs">
      <h2>CATEGORIES & UPDATES</h2>
      <p className="h4">Get MAXapps updates to your inbox</p>
      <p>
        Be the first to learn about new MAXapps features, best practices, and
        community events.
      </p>
      <div className="d-flex py-3">
        <input
          name="email"
          type="email"
          placeholder="you@example.xyz"
          value={mail}
          required="required"
          onChange={e => setMail(e.target.value)}
        />

        <button
          type="submit"
          onClick={async () => {
            mail ? await sendUserMail() : setRequiredd(true)
          }}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"></path>
          </svg>{" "}
        </button>
      </div>
      {requiredd && (
        <label htmlFor="error-message" className="error-message">
          *This field is required <br />
        </label>
      )}
      {showSuccess && (
        <label htmlFor="success-message" className="success-message">
          Success! You have been subscribed to the Maxapps newsletter. <br />
        </label>
      )}
      <div>
        {tagslist?.map((data, index) => (
          <Link
            className="alltags m-1"
            key={index}
            to={`/tags/${kebabCase(data.fieldValue)}`}
          >
            {data.fieldValue} ({data.totalCount})
          </Link>
        ))}
      </div>
    </div>
  )
}

export default More

import React from "react"
import { Link } from "gatsby"
import "./index.scss"
import { kebabCase } from "lodash"
import More from "./more"
import { Helmet } from "react-helmet"

const TagsDesc = ({ tagHeader, edges }) => {
  const data = edges
    .slice()
    .sort(
      (a, b) =>
        new Date(b.node.frontmatter.date?.replace("|", "")) -
        new Date(a.node.frontmatter.date?.replace("|", ""))
    )
  var dataHeader = []
  var dataOthers = []
  if (data.length >= 3) {
    dataHeader = data.slice(0, 3)
    dataOthers = data.slice(3, data.length)
  } else {
    dataHeader = data
  }

  return (
    <div id="TagsDesc" className="TagsDesc py-5">
      <Helmet prepend>
        <title>{tagHeader}</title>
        <meta property="og:title" content={`MAXapps Maximo: ${tagHeader}`} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@maxapps" />
      </Helmet>
      <h1 className="py-4">
        <Link className=" py-3" to={`/blog/`}>
          Blog
        </Link>
        / {tagHeader}
      </h1>
      <div className="content">
        {dataHeader?.map(({ node }) => {
          const { slug } = node
          const { title, description, author, date, tags } = node.frontmatter
          return (
            <div className=" py-3 p-lg-0 blogs" key={slug}>
              <Link className="titlelink py-3" to={`/blog/${slug}`}>
                {title}
              </Link>
              <p className="desc">{description}</p>
              <p>
                {author} • {date}
              </p>
              <p>
                #
                {tags?.map(tag => (
                  <Link className="taglink" to={`/tags/${kebabCase(tag)}`}>
                    {tag}
                  </Link>
                ))}
              </p>
            </div>
          )
        })}
        <div className="  moreblogs">
          {dataOthers?.length > 0 && (
            <>
              <h2>Read More</h2>
              {dataOthers?.map(({ node }) => {
                const { slug } = node
                const { title, description, author, date, tags } =
                  node.frontmatter
                return (
                  <div className=" py-3 p-lg-0 blogs" key={slug}>
                    <Link className="titlelink py-3" to={`/blog/${slug}`}>
                      {title}
                    </Link>
                    <p className="desc">{description}</p>
                    <p>
                      {author} • {date}
                    </p>
                    <p>
                      #
                      {tags?.map(tag => (
                        <Link
                          className="taglink"
                          to={`/tags/${tag?.toLowerCase()}`}
                        >
                          {tag}
                        </Link>
                      ))}
                    </p>
                  </div>
                )
              })}{" "}
            </>
          )}
        </div>

        <More />
      </div>
    </div>
  )
}

export default TagsDesc

import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout/t1/layout"

import { graphql } from "gatsby"
import TagsDesc from "../components/tags/tags-desc"

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMdx

  const tagHeader = `${tag?.toUpperCase()} (${totalCount})  `

  return (
    <Layout>
      <TagsDesc tagHeader={tagHeader} edges={edges} />
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            slug: PropTypes.string.isRequired,
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query ($tag: String) {
    allMdx(
      limit: 2000
      filter: {
        frontmatter: { tags: { in: [$tag] } }
        fileAbsolutePath: { regex: "/blog/" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          slug
          frontmatter {
            title
            tags
            description
            author
            date
          }
        }
      }
    }
  }
`
